document.addEventListener('DOMContentLoaded', function () {
  const header = document.querySelector('.header');
  const navbar = document.querySelector('.navbar');
  const logo = document.querySelector('.navbar__logo > img');
  window.addEventListener('scroll', function () {
    if (window.scrollY > header.offsetHeight - 60) {
      navbar.classList.add('navbar--scrolled');
      logo.src = logo.src.replace('logo.webp', 'logo-scroll.webp');
    } else {
      navbar.classList.remove('navbar--scrolled');
      logo.src = logo.src.replace('logo-scroll.webp', 'logo.webp');
    }
  });

  //menu mobile
  const menuBtn = document.querySelector('.navbar__burger');
  const menu = document.querySelector('.navbar__list');

  menuBtn.addEventListener('click', function () {
    menu.classList.toggle('navbar__list--active');
    menuBtn.classList.toggle('navbar__burger--active');
  });

  AOS.init({
    duration: 700,
    delay: 100,
    once: true,
    disable: 'mobile',
  });

  const sousmenu = document.querySelectorAll('.navbar__item--sousmenu');

  sousmenu.forEach((item) => {
    item.addEventListener('click', function () {
      item.querySelector('div').classList.toggle('navbar__sousmenu--active');
    });
  });
});
